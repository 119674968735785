// Color system
$primary: #fb4444;
$secondary: #1e1d85;
$accent: #eff6f7;
$orange: #ff6935;
$yellow: #f6b500;
$dark: #252525;
$text-muted: #ababab;

$colors: (
		"accent": $accent
);
$white: #fff;
$indigo: #6969eb;

$theme-colors: (
		"accent": $accent,
		'orange':$orange,
		'white':$white,
		'indigo':$indigo
);

// Body
//
// Settings for the `<body>` element.

$body-color: #696969;

$headings-color: #333;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable_shadows: true;

// Components
//
// Define common padding and border radius sizes and more.
$caret-spacing: 0.25rem;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.875rem;
$line-height-base: 1.86 !default;

$font-size-lg: 1rem;
$font-size-sm: 0.75rem;

$line-height-lg: 2;

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$display1-size: 6.8125rem; //109px
$display2-size: 4.0625rem; //65px
$display3-size: 3.1875rem; //51px
$display4-size: 1.875rem;

$display1-weight: 500;
$display2-weight: 500;
$display3-weight: 500;
$display4-weight: 600;

// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 3px;
$border-radius-lg: 5px;
$border-color: #eeeeee;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
		sm: 770px,
		md: 992px,
		lg: 1200px,
);
$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1600px
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: (
		0: 0,
		1: ($spacer * .3125), //5px
		2: ($spacer * .625), //10px
		3: $spacer, //16px
		4: ($spacer * 1.25), //20px
		5: ($spacer * 1.5), //24px
		6: ($spacer * 1.875), //30px
		7: ($spacer * 2.5), //40px
		8: ($spacer * 3.125), //50px
		9: ($spacer * 3.75), //60px
		10: ($spacer * 4.375), //70px
		11: ($spacer * 5), //80px
		12: ($spacer * 5.625), //90px
		13: ($spacer * 6.25), //100px
		14: ($spacer * 6.875), //110px
		15: ($spacer * 7.5), //120px
		16:($spacer * 9.375), //150px
		17:($spacer * 10.625), //170px
		18:($spacer * 13.75) //220px
);

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-font-weight: 500;

$btn-padding-x-lg: 2.1875rem;

$btn-padding-x: 1.5rem;

$input-font-weight: 600;
$input-bg: #f8f8f8;
$input-color: $body-color;
$input-border-color: #ededed;
$input-border-width: 2px;
$input-box-shadow: none;
$input-placeholder-color: #ababab;
$input-btn-font-size: 0.8125rem;
$input-btn-padding-y: 0.375rem;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius;

$input-btn-font-size-lg: $font-size-base;
$input-btn-padding-y-lg: 0.625rem;

$btn-box-shadow: none;
$btn-padding-y-sm: 0.3125rem;
$btn-padding-y: .4375rem;
$btn-padding-y-lg: 0.6875rem;

// Nav Tabs
$nav-tabs-link-active-color: $primary;
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 5px;

// Navbar
$navbar-light-color: #333;

/*$navbar-padding-y: 1.5rem;
$navbar-padding-x: 0;

$nav-link-padding-y: 1rem;

$navbar-nav-link-padding-x: 1.25rem;
$navbar-nav-link-padding-x: 1.25rem;*/

$navbar-dark-color: #fff;
$navbar-dark-hover-color: #fff;
$navbar-dark-active-color: #fff;
$navbar-dark-disabled-color: #f5f5f5;

$display-line-height: 1.2;

// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-color: $body-color;
$dropdown-spacer: 0;
$dropdown-padding-y: 1rem;
$dropdown-border-width: 0;
$dropdown-box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15);
$dropdown-border-radius: 5px;

$dropdown-header-color: #252525;

// Breadcrumbs

$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: #333;
$breadcrumb-active-color: #333;

$card-border-radius: 5px;
$card-border-color: $border-color;

$card-img-overlay-padding: 0.625rem;

$box-shadow-sm: 0 5px 20px 0 rgba(51, 51, 51, 0.08);
$box-shadow: 0 11px 30px 0 rgba(51, 51, 51, 0.15);
$box-shadow-lg: 0 30px 50px 0 rgba(51, 51, 51, 0.1);

// Breadcrumbs

$breadcrumb-font-size: 14px;

$breadcrumb-divider-color: currentColor;
$breadcrumb-active-color: #333333;

$badge-border-radius: $border-radius;
$badge-padding-y: 5px;
$badge-padding-x: 8px;

// List group
$list-group-border-color: $border-color;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-head-color: #333;

// List group
$list-group-hover-bg: #f8f8f8;

$badge-font-size: 13px;
$badge-font-weight: 500;
$custom-control-gutter: 0.75rem;
$custom-control-indicator-size: 1.125rem;
$custom-control-indicator-border-color: transparent;
$custom-control-indicator-border-width: 0;
$custom-control-indicator-focus-box-shadow: none;
$custom-control-indicator-focus-border-color: transparent;
$custom-control-indicator-bg: $border-color;

$pagination-color: #333;
$pagination-border-width: 0;
$pagination-bg: transparent;

$pagination-hover-color: #fff;
$pagination-hover-bg: $primary;
$pagination-hover-border-color: $primary;

$pagination-active-color: #fff;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;

//Popovers
$popover-border-width: 0;
$popover-border-radius: $border-radius;
$popover-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);

$popover-body-padding-y: 15px;
$popover-body-padding-x: 10px;
